import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom";
import { tableEach } from "../store/dbSlice";
import { dispNum, dispUnit } from "../helpers";
import Header from "../Header";
import { Modal } from "react-bootstrap";
import Page from "../Page";
import { foodClass, foodIconClass, FoodName } from "../common/FoodName";
import PInput from "../common/Pinp";
import {
	doFoodCalculate, doFoodDelete
	, doFoodItemAmountSet, doFoodItemDelete, doFoodNetWeightSet, doFoodRename, doFoodUnitSet, doRecipeIntoBox
} from "../store/foods";


export default function Recipe() {

	const { recipeId } = useParams();

	const [recipe, setRecipe] = useState(null);
	const foods = useSelector(state => state.db.foods);
	const units = useSelector(state => state.db.units);

	/*const [wantedCHg, setWantedCHg] = useState(40);
	const [finalG, setFinalG] = useState(0);*/

	const [isEdited, setIsEdited] = useState(false);
	const [editedName, setEditedName] = useState("");
	const [locked, setLocked] = useState(0);
	const [closable, setClosable] = useState(false);
	const [showFinish, setShowFinish] = useState(false);
	//const [sumUsedAmount, setSumUsedAmount] = useState(0);
	//const [finishConfirmAmount, setFinishConfirmAmount] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (recipeId)
			doFoodCalculate(recipeId);
	}, [recipeId, foods.byId[recipeId]]);

	useEffect(() => {
		let _recipe = foods.byId[recipeId];
		if (!_recipe)
			return; // db not loaded yet
		if (_recipe.isRemoved) {
			navigate('/recipe');
		}

		setRecipe(_recipe);

		let _closable = _recipe.items.allIds.length > 0 && _recipe.netWeightG > 0;
		setClosable(_closable);

		setLocked(false);
	});




	const showEdit = () => {
		setEditedName(recipe.name);
		setIsEdited(true);
	}

	const submitEdit = () => {
		doFoodRename(recipeId, editedName);
		setIsEdited(false);
	}

	const intoBox = () => {
		setEditedName(recipe.name);
		setShowFinish(true);
	}

	const intoBoxIt = () => {
		if (editedName !== recipe.name)
			doFoodRename(recipe.id, editedName);
		doRecipeIntoBox(recipe.id);
		navigate("/boxed");
	}


	const setWeightAsSum = () => {
		let sum = 0;
		for (let item of tableEach(recipe.items)) {
			sum += parseFloat(item.amount) || 0;
		}
		doFoodNetWeightSet(recipeId, sum);
	}



	const recipeDelete = () => {
		if (window.confirm("Biztos törlöd?")) {
			doFoodDelete(recipeId);
			navigate('/recipe')
		}
	}

	const deleteItem = (itemKey) => {
		//if (window.confirm(`Törlöd?`))
		doFoodItemDelete(recipeId, itemKey, null)
	}


	if (!recipe)
		return (<div>...</div>);

	return (
		<Page>
			<Page.Header>
				<Header
					titleClass={foodClass(recipe)}
					title={recipe?.name || '?Új'}
					titleTool={<button className="title-tool" onClick={() => showEdit()} ><i className={`bi ${foodIconClass(recipe)}`}></i></button>}
					right={!locked && <Link to={`/recipe/${recipeId}/foods`} className="title-tool-text">+ <i className="bi bi-apple"></i></Link>}

				/>
				{recipe.parents?.allIds?.length>0 && <div className="headline">
					&lt;&nbsp;
					{recipe.parents.allIds
						.map(parentKey => foods.byId[parentKey])
						.map(parent => <FoodName key={parent.id} food={parent} />)}
				</div>}

			</Page.Header>
			<Page.Body>
				<div className="table-responsive">
					<table className="table table-sm">
						<tbody>
							{recipe.items && recipe.items.allIds
								.map(id => recipe.items.byId[id])
								.map(item => ({ item, food: foods.byId[item.foodKey] }))
								.map(({ item, food }, ix) => (
									<React.Fragment key={item.id}>
										<tr className={ix % 2 ? 'odd' : 'even'}>
											<th colSpan="5"><FoodName food={food} /></th>
											<td align="right">{!locked && <button className="btn table-tool"
												onClick={() => deleteItem(item.id)} ><i className="bi bi-x-lg"></i></button>}
											</td>
										</tr>
										<tr className={ix % 2 ? 'odd' : 'even'}>
											<td className="amount">
												<span className="CH">{dispNum(food.chPerUnit)}</span>
											</td>
											<td className="dim">
												gCH /{dispUnit(food)}
											</td>
											<td className="amount">
												<PInput.Num
													disabled={locked ? '1' : ''}
													className="form-control small-amount AMT"
													value={item.amount}
													onChange={(e) => doFoodItemAmountSet(recipeId, item.id, e.target.value)} />
											</td>
											<td className="dim">
												<span>g</span>
											</td>
											<td className="amount">
												<span className="CH">{dispNum(item.CHg)}</span>
											</td>
											<td className="dim">
												<span>gCH</span>
											</td>
										</tr>
									</React.Fragment>
								))}
						</tbody>
					</table>
				</div>
			</Page.Body>


			<Page.Footer>
				<div className="footer">
					<div>
						<div className="tools btn-group-vertical px-2">

							<Link className="btn btn-outline-primary" to={`/meals/addfood/${recipe.id}`} ><i className={`bi bi-egg-fried`}></i>&nbsp;Étkezésre</Link>
							<button className="btn btn-outline-primary" disabled={!closable} onClick={() => intoBox()} ><i className="bi bi-archive-fill"></i>&nbsp;Dobozba</button>
							<button className="btn btn-outline-primary" onClick={() => recipeDelete()} ><span className="bi bi-trash"></span>&nbsp;Töröl</button>
						</div>
					</div>

					<div className={`alert ${foodClass(recipe)}`} role="alert">
						<table className="table table-sm">
							<tbody>
								<tr>
									<th>&sum;CH</th>
									<td className="amount">{dispNum(recipe.sumCHg)}</td>
									<td className="dim">gCH</td>
								</tr>
								<tr>
									<th>Kész</th>
									<td className="amount">
										<div className="input-groupx">
											<button className="btn  btn-outline-primary btn-sm" onClick={() => setWeightAsSum()} >+</button>
											<PInput.Num disabled={locked ? '1' : ''} className="form-control small-amount AMT" value={recipe.netWeightG || 0} onChange={(e) => doFoodNetWeightSet(recipeId, e.target.value)} />
										</div>
									</td>
									<td className="dim">
										<select className="form-control AMT unit-select-mini" value={recipe.unitId} onChange={(e) => doFoodUnitSet(recipeId, e.target.value)}>
											{tableEach(units).map(unit => <option key={unit.id} value={unit.id}>{unit.id}</option>)}
										</select>
									</td>
								</tr>
								<tr>
									<th>{dispUnit(recipe)}</th>
									<td className="amount"><span className="CH inline-CH">{dispNum(recipe.chPerUnit)}</span></td>
									<td className="dim">gCH</td>
								</tr>

							</tbody>
						</table>
					</div>
				</div>




			</Page.Footer>

			<Modal
				show={isEdited}
				centered={true}
				onHide={() => setIsEdited(false)}
			>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>
					<label className="form-label">Név</label>
					<input type="text" className="form-control" placeholder="Étel neve" value={editedName} onChange={(e) => setEditedName(e.target.value)} />
				</Modal.Body>
				<Modal.Footer>
					<button type="submit" className="btn btn-primary" onClick={() => submitEdit()} ><span className="bi bi-save"></span>&nbsp;Ment</button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showFinish}
				centered={true}
				onHide={() => setShowFinish(false)}
			>
				<Modal.Header>
					Dobozba vele?
				</Modal.Header>
				<Modal.Body>
					{!recipe.isRenamed && <div className="alert alert-warn">Jó ez a név?</div>}
					<input autoFocus className="form-control" value={editedName} onChange={(e) => setEditedName(e.target.value)} />


				</Modal.Body>
				<Modal.Footer>
					<button type="submit" className="btn btn-outline-primary" onClick={() => setShowFinish(false)} >Mégse</button>
					<button type="submit" className="btn btn-primary" onClick={() => intoBoxIt()} ><i className="bi bi-archive-fill"></i>&nbsp;Dobozba</button>
				</Modal.Footer>
			</Modal>

		</Page>




	);
}