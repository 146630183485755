import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FoodName } from "../common/FoodName";
import PInput from "../common/Pinp";
import Header from "../Header";
import { dispNum, dispUnit } from "../helpers";
import Page from "../Page";
import { tableEach } from "../store/dbSlice";
import { doMealCalculate, doMealDelete, doMealItemAmountSet, doMealItemCHSet, doMealItemDelete, doMealRename, doMealTargetCHgSet } from "../store/meals";
import styles from './meal.scss';

export default function Meal() {

	const { mealId } = useParams();
	const [meal, setMeal] = useState(null);
	const meals = useSelector(state => state.db.meals);
	const foods = useSelector(state => state.db.foods);

	const [newerRecipeWarn, setNewerRecipeWarn] = useState(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (mealId)
			doMealCalculate(mealId);
	}, [mealId, meals.byId[mealId]]);

	useEffect(() => {
		let _meal = meals.byId[mealId];
		if (!_meal)
			return; // db not loaded yet
		if (_meal.isRemoved) {
			navigate('/meals');
		}
		setMeal(_meal);
	});

	const getItems = (meal, foods) => {
		return meal.items.allIds
			.map(itemId => {
				let item = meal.items.byId[itemId];
				let food = foods.byId[item.foodKey];
				let recipe;
				let newerRecipe = false;
				if (food.kind === 'boxed') {
					recipe = foods.byId[food.recipeKey];
					if (recipe
						&& (recipe.chPerUnit !== food.chPerUnit || recipe.unitMupltip !== food.unitMupltip || recipe.unitId !== food.unitId))
						newerRecipe = true;
				}

				return { item, food, recipe, newerRecipe };
			})
	}

	const swapIsCHSet = (item) => {
		if (item.isCHSet) {
			doMealItemAmountSet(mealId, item.id, item.amount);
		} else {
			doMealItemCHSet(mealId, item.id, item.CHg);
		}

	}

	const rename = () => {
		let newName = window.prompt("Új név?", meal.name);
		if (newName) {
			doMealRename(mealId, newName);
		}
	}

	const deleteIt = () => {
		if (window.confirm(`Biztosan törlöd ezt '${meal.name}'?`)) {
			doMealDelete(mealId);
			navigate('/meals');
		}
	}

	const zeroIt = () => {
		if (window.confirm("Biztosan kinullázol mindent?")) {
			for (let item of tableEach(meal.items)) {
				doMealItemAmountSet(meal.id, item.id, 0);
			}
		}
	}

	const emptyIt = () => {
		if (window.confirm("Biztosan leszedsz mindent?")) {
			for (let item of tableEach(meal.items)) {
				doMealItemDelete(meal.id, item.id);
			}
		}
	}

	const deleteItem = (itemKey) => {
		//if (window.confirm("Törlöd?"))
		doMealItemDelete(mealId, itemKey, null)
	}

	return (meal && <Page>
		<Page.Header>
			<Header
				title={meal?.name || 'loading..'}
				titleClass="food-meal"
				titleTool={<button className="title-tool" onClick={() => rename()} ><i className="bi bi-pencil"></i></button>}
				right={<Link className="title-tool-text" to={`/meal/${meal.id}/foods`}>+ <i className="bi bi-apple"></i></Link>}
				showNav={true}
			/>
		</Page.Header>
		{meal && <Page.Body>

			<div className="table-responsive">
				<table className="table">
					<tbody>
						{!!meal && getItems(meal, foods)
							.map(({ item, food, recipe, newerRecipe }, ix) => <React.Fragment key={item.id}>
								<tr className={ix % 2 ? 'odd' : 'even'}>
									<td colSpan="5"  >
										{food
											? (!newerRecipe
												? <FoodName food={food} />
												: <FoodName food={food} badge="!" onClick={() => setNewerRecipeWarn({ item, boxed: food, recipe })} />)
											: 'loading...'}
									</td>
									<td>
										<button className="btn table-tool"
											onClick={() => deleteItem(item.id)} ><i className="bi bi-x-lg"></i></button>
									</td>
								</tr>
								{food && <tr className={ix % 2 ? 'odd' : 'even'}>
									<td className="amount small-amount" style={{ align: 'right' }}>
										{dispNum(food?.chPerUnit)}
									</td>
									<td className="dim" >gCH<br />/{dispUnit(food)}</td>
									<td className="amount small-amount">{
										!item.isCHSet
											? <div>
												<PInput.Num className="form-control small-amount AMT"
													disabled={item.isCHSet}
													value={item.amount}
													onChange={(e) => doMealItemAmountSet(mealId, item.id, e.target.value)} />
											</div>
											: <span className="AMT AMT-inline" onClick={() => swapIsCHSet(item)}>{dispNum(item.amount)}</span>
									}
									</td>
									<td className="dim">{food.unitId}</td>
									<td className="amount  small-amount">{
										item.isCHSet
											? <div><PInput.Num className="form-control small-amount CH"
												value={item.CHg}
												onChange={(e) => doMealItemCHSet(mealId, item.id, e.target.value)} />
											</div>
											: <span className="CH CH-inline" onClick={() => swapIsCHSet(item)} >{dispNum(item.CHg)}</span>
									}
									</td>
									<td className="dim">gCH</td>
								</tr>}
							</React.Fragment>)}
					</tbody>
				</table>
			</div>
		</Page.Body>}
		{meal && <Page.Footer>
			<div className="footer">
				<div>
					<div className="tools btn-group-vertical px-2">
						{false && <Link to={`/meal/${mealId}/change-meal`} className="btn btn-primary" ><i className="bi bi-arrow-repeat"></i>&nbsp;Másik</Link>}
						<button className="btn btn-outline-secondary" onClick={() => zeroIt()} ><i className="bi bi-c-square-fill"></i>&nbsp;Nulláz</button>
						<button className="btn btn-outline-secondary" onClick={() => emptyIt()} ><i className="bi bi-eraser-fill"></i>&nbsp;Kiürít</button>
						<button className="btn btn-outline-secondary" onClick={() => deleteIt()} ><i className="bi bi-trash"></i>&nbsp;Töröl</button>
					</div>
				</div>
				<div className="alert food-meal">
					<table>
						<tbody>
							<tr>
								<th className="text-end">Cél:</th>
								<td className="amount">
									<PInput.Num className="form-control small-amount CH" value={meal.targetCHg}
										onChange={(e) => doMealTargetCHgSet(mealId, parseFloat(e.target.value))} />
								</td>
								<td className="dim" >CHg</td>
							</tr>
							<tr>
								<th>Összesen:</th>
								<td className="amount">
									<span className="CH inline-CH">{dispNum(meal.sumCHg)}</span>
								</td>
								<td className="dim" >CHg</td>
							</tr>
							<tr>
								<th>Még kell:</th>
								<td className="amount">
									<span className="CH inline-CH">{dispNum(meal.targetCHg - meal.sumCHg)}</span>
								</td>
								<td className="dim" >CHg</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>




		</Page.Footer>}

		<Modal
			show={newerRecipeWarn}
			centered={true}
			onHide={() => setNewerRecipeWarn(false)}
		>
			{!!newerRecipeWarn && <React.Fragment>
			<Modal.Header closeButton>
			</Modal.Header>
			<Modal.Body>
				<div className="alert alert-danger">
					A recept közben megváltozott!
				</div>
				<b>Az itt használt kész étel:</b>
				<br />
				<span className="CH inline-CH">{newerRecipeWarn.boxed.chPerUnit}</span><span className="dim">CHg/{dispUnit(newerRecipeWarn.boxed)}</span>
				<br />
				<b>A recept most:</b><br />
				<span className="CH inline-CH">{newerRecipeWarn.recipe.chPerUnit}</span><span className="dim">CHg/{dispUnit(newerRecipeWarn.recipe)}</span>
			</Modal.Body>
			<Modal.Footer>
				<Link className="btn btn-primary" to={`/recipe/${newerRecipeWarn.recipe.id}`} ><i className="bi bi-receipt"></i>&nbsp;Ugrás a receptre</Link>
			</Modal.Footer>
			</React.Fragment>}
		</Modal>


	</Page>
	);
}