import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom"
import { FoodName } from "../common/FoodName";
import PInput from "../common/Pinp";
import Header from "../Header";
import { doBoxedAmountSet, doBoxedSplit, doFoodDelete, doFoodItemAdd, doFoodRename, doFoodSave } from "../store/foods";

export default function Boxed() {
	const { boxedId } = useParams();

	const navigate = useNavigate();

	const foods = useSelector(state => state.db.foods)
	const [boxed, setBoxed] = useState();
	const [recipe, setRecipe] = useState();

	useEffect(() => {
		let _boxed = boxedId ? foods.byId[boxedId] : null;
		setBoxed(_boxed);
		let _recipe = _boxed?.recipeKey ? foods.byId[_boxed?.recipeKey] : null;
		setRecipe(_recipe);
	}, [boxedId, boxed?.name, foods.byId]);

	const rename = () => {
		let newName = window.prompt("Új név?", boxed.name);
		if (newName) {
			doFoodRename(boxedId, newName);
		}
	}

	const deleteIt = () => {
		if (window.confirm("Biztosan törlöd?")) {
			doFoodDelete(boxedId);
			navigate(-1);
		}
	}

	const splitIt = () => {
		let amount = parseFloat(window.prompt("Mennyit teszel át másik dobozba?", boxed.amount));
		if (amount > 0 && amount < boxed.amount) {
			doBoxedSplit(boxedId, amount);
		}
	}

	return (boxed && <div>
		<Header
			
			title={boxed.name}
			titleTool={<button className="btn btn-light" onClick={() => rename()} ><i className="bi bi-archive-fill"></i></button>}
		/>
		<div className="alert food-boxed" role="alert">
			Mennyiség: 
			<PInput.Num
				className="form-control small-amount AMT"
				value={boxed.amount}
				onChange={(e) => doBoxedAmountSet(boxedId, e.target.value)} />
			&nbsp;{boxed.unitId}
			<br />
			<br />
			Recept:&nbsp;{recipe ? <FoodName food={recipe} />  : '?'}


		</div>
		<div className="btn-group">
			<button className="btn btn-outline-primary" onClick={() => deleteIt()} ><i className="bi bi-trash"></i>&nbsp;Törlés</button>
			<button className="btn btn-outline-primary" onClick={() => splitIt()} ><i className="bi bi-arrows-expand"></i>&nbsp;Kettéosztom</button>
		</div>
		<div className="btn-group">
			<button className="btn btn-outline-primary" onClick={() => rename()} ><i className="bi bi-pencil"></i>&nbsp;Átnevezem</button>
		</div>


	</div>)
}