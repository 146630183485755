import React from "react";
import { Modal } from "react-bootstrap";

export default function Settings() {


	return (
		<React.Fragment>
			<Modal.Body>
				
			</Modal.Body>
		</React.Fragment>
	)
}  