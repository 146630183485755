import { useDispatch } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import styles from "./header.scss"

export default function Header({ title, titleClass, titleTool, right, left, showNav }) {

	return (<div className='page-header'>
		<div className={"header "+(titleClass)}>
			{/* onClick={() => dispatch(isHomeShowSet(true))} */}
			<div className="left btn-group">
				{!left && <a className="title-tool-text"	href='#home'><i className="bi bi-list"></i></a>}
				{left}
			</div>
			<div className={"mid "}>
				{titleTool}
				<span className="title-text">{title}</span>
			</div>
			<div className="right">{right}</div>
		</div>
	</div>);
}

Header.BackTo = function({to}) {
	return (<Link className="btn btn-primary btn-sm" to={to} >&lt;&nbsp;Kész</Link>);
}

const HeaderBack = function() {
	const navigate = useNavigate();

	return (<button className="btn btn-primary btn-sm" onClick={() => navigate(-1)} >&lt;&nbsp;Kész</button>);
}

Header.Back = HeaderBack;