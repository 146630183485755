import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import styles from "./page.scss"

export default function Page({ children }) {
	return (<React.Fragment>{children}</React.Fragment>);
	//return (<div className="page-containerx">{children}</div>);
}

Page.Header = function ({ children }) {
	return (<div className="page-header">{children}</div>);
}

Page.Body = function ({ children }) {

	return (<div className="page-body">
			<Scrollbars style={{ width: '100%', height: '100%' }}>
				{children}
			</Scrollbars>
		</div>
	);
}

Page.Footer = function ({ children }) {
	return (<div className="page-footer">{children}</div>);
}

