import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom";
import { savedFoodIdSet, tableEach } from "../store/dbSlice";
import { dispNum, dispUnit, usePrevious } from "../helpers";
import Header from "../Header";
import Page from "../Page";
import { FoodName, MealName } from "../common/FoodName";
import { doBoxedFIX, doFoodDelete, doFoodFinalDelete, doFoodItemAdd, doFoodItemDelete, doFoodSave, doFoodUndoDelete } from "../store/foods";
import { doMealItemAdd, doMealItemDelete } from "../store/meals";


export default function Foods({ mode, backPath }) {

	const { mealId, recipeId } = useParams();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [meal, setMeal] = useState(null);
	const [recipe, setRecipe] = useState(null);
	const [checkedIds, setCheckedIds] = useState([]);
	const [q, setQ] = useState("");
	//const [editedFood, setEditedFood] = useState(null);
	const foods = useSelector(state => state.db.foods)
	const meals = useSelector(state => state.db.meals)
	const savedFoodId = useSelector(state => state.db.savedFoodId);
	const searchRef = useRef();
	const [adminFilter, setAdminFilter] = useState("deleted");

	function search(items) {
		// --------------------- admin -------------------
		if (mode==='all') {
			return items.allIds
			.map(id => items.byId[id])
			.filter(food => 
				!adminFilter
				|| (adminFilter === 'deleted' && food.isRemoved)
			)
			.filter(food => {
				return food.name.toLocaleUpperCase().indexOf(q.toLocaleUpperCase()) >= 0;
			})
			.sort((a, b) => a.name?.localeCompare(b.name))

		}
		// --------------------- normal -------------------
		return items
			.allIds
			.map(id => items.byId[id])
			.filter(food => !food.isRemoved)
			//.filter(food => (!recipe && !meal) || food.kind === 'basic' || (recipe?.kind !== 'recipe' && food.kind === 'boxed'))

			// ===== add =====
			// ----- önmagához ne ---
			.filter(food => !recipe || recipe.id !== food.id) 
			// ----- parentet ne ----
			.filter(food => !recipe 
				|| food.tier !== 'parent')
			// ----- hozzáadhatok -----
			.filter(food => !recipe 
				|| food.kind === 'basic' 
				|| food.kind === 'boxed'
				|| (food.kind === 'recipe' && recipe.tier !== 'child') // receptet, csak akkor, ha a 'szülő recept' nem 'child'
			)
			// ====== list ======
			.filter(food => !mode || food.kind === mode)
			.filter(food => {
				return food.name.toLocaleUpperCase().indexOf(q.toLocaleUpperCase()) >= 0;
			})
			.sort((a, b) => a.name?.localeCompare(b.name))
		;
		
	}

	useEffect(() => {
		setQ('');
	}, [mode]);

	const prevSavedFoodId = usePrevious(savedFoodId);
	useEffect(() => {
		if (savedFoodId && savedFoodId !== prevSavedFoodId) { // valaki rögzített egy ételt
			let newFood = foods.byId[savedFoodId];
			if (!newFood) {
				console.warn(`New food not yet arrived ${savedFoodId}`);
				return;
			}
			if (recipe) {
				doFoodItemAdd(recipeId, newFood);
			}
			if (meal) {
				doMealItemAdd(mealId, newFood);
			}
			setQ(newFood.name);
			dispatch(savedFoodIdSet(null));
		}
		
	}, [savedFoodId]);

	useEffect(() => {
		let _meal = mealId ? meals.byId[mealId] : null;
		setMeal(_meal);

		let _recipe = recipeId ? foods.byId[recipeId] : null;
		setRecipe(_recipe);
		let oldCheckedIds = checkedIds.join(";");
		let newCheckedIds = [];
		//console.log('---------- checkedIds ----------------')
		if (_recipe && _recipe.items) {
			for (let itemId of _recipe.items.allIds) {
				let item = _recipe.items.byId[itemId];
				//console.log(itemId, item);
				newCheckedIds.push(item.foodKey);
			}
		} else if (_meal) {
			for (let mi of tableEach(_meal.items)) {
				newCheckedIds.push(mi.foodKey);
			}

		}
		if (oldCheckedIds !== newCheckedIds.join(";"))
			setCheckedIds(newCheckedIds);
		//console.log(checkedIds);
	}, [mealId, meals.byId, recipeId, foods.byId, checkedIds]);

	const newRecipe = () => {
		let newRecipe = {
			kind: 'recipe',
			name: 'Új recept'
		};
		setQ("");
		doFoodSave(newRecipe);
		navigate('/recipe/' + newRecipe.id);
	}

	const otherTitle = () => {
		if (mode === 'boxed') return 'Dobozolva';
		if (mode === 'recipe') return 'Receptek';
		return 'Ételek'
	}

	// -------------------- admin -----------------------
	const finalDeleteIt = (foodKey) => {
		if (window.confirm("Véglegesen törlöd?")) {
			doFoodFinalDelete(foodKey);
		}
	}

	const undoDeleteIt = (foodKey) => {
		doFoodUndoDelete(foodKey);
	}

	const deleteIt = (foodKey) => {
		doFoodDelete(foodKey);
	}

	return (
		<Page>
			<Page.Header>
				<Header
					left={recipeId
						? <Header.BackTo to={"/recipe/" + recipeId} />
						: (mealId ? <Header.Back /> : false)
					}

					right={(mode === 'recipe' || mode === 'boxed')
						? <button className="btn title-tool-text" onClick={() => newRecipe()} >Új recept</button>
						: <Link className="btn title-tool-text"  to={`#foodedit/new/${encodeURIComponent(q)}`} onClick={() => dispatch(savedFoodIdSet(null))} ><i className="bi bi-apple"></i>&nbsp;Új</Link>
					}
					title={recipe
						? <React.Fragment><FoodName food={recipe} /></React.Fragment>
						: (meal ? <React.Fragment><MealName meal={meal} /></React.Fragment>
							: otherTitle())}
				/>
				<div className="search-container btn-group">
					<input ref={searchRef} type="text" className="form-control" value={q} onChange={e => setQ(e.target.value)} />
					<button className="btn btn-outline-primary" onClick={() => {searchRef.current.focus(); searchRef.current.select()}} ><i className="bi bi-search" tabIndex="-1" ></i></button>
				</div>
			</Page.Header>
			{mode==='all' && <div>
				<button onClick={()=>setAdminFilter("")} className={`btn btn-sm ${!adminFilter?"btn-primary":"btn-outline-primary"}`}>ALL</button>
				<button onClick={()=>setAdminFilter("deleted")} className={`btn btn-sm ${adminFilter=='deleted'?"btn-primary":"btn-outline-primary"}`}>DELETED</button>
			</div>}
			<Page.Body>
				<table className="table">
					<tbody>
						{search(foods)
							.map(food => {
								let checked = checkedIds.indexOf(food.id) >= 0;
								return { food, checked }
							})
							.map(({ food, checked }, ix) => <React.Fragment key={food.id}>
								<tr className={ix%2?'odd':'even'}>
									<td colSpan={3}><FoodName food={food} /></td>
									<td rowSpan={2}  align="right">
										{recipe && !checked && <button className="foodcheck btn btn-link btn-lg" onClick={() => doFoodItemAdd(recipe.id, food)} ><i className="bi bi-square"></i></button>}
										{recipe && checked && <button className="foodcheck btn btn-link btn-lg" onClick={() => doFoodItemDelete(recipe.id, null, food.id)} ><i className="bi bi-check-square-fill"></i></button>}
										{!recipe && meal && !checked && <button className="foodcheck btn btn-link btn-lg" onClick={() => doMealItemAdd(mealId, food)} ><i className="bi bi-square"></i></button>}
										{!recipe && meal && checked && <button className="foodcheck btn btn-link btn-lg" onClick={() => doMealItemDelete(mealId, null, food.id)} ><i className="bi bi-check-square-fill"></i></button>}
										{mode==='all' && <div>
											{food.isRemoved ? <div>
												<button className="btn table-tool" onClick={() => undoDeleteIt(food.id)} ><i className="bi bi-recycle"></i></button>
												<button className="btn table-tool" onClick={() => finalDeleteIt(food.id)} ><i className="bi bi-x-lg text-danger"></i></button>
												</div> : <div>
												<button className="btn table-tool" onClick={() => deleteIt(food.id)} ><i className="bi bi-x-lg"></i></button>
												</div>}
											</div>
										}
									</td>

								</tr>
								<tr className={ix%2?'odd':'even'}>
									<td className="amount">
										<span className="CH">{dispNum(food.chPerUnit)}</span>
										<span className="dim">/ {dispUnit(food)}</span>
									</td>
									<td className="amount">
										{!!food.amount && <span className="AMT text-AMT">{dispNum(food.amount)}&nbsp;</span>}
										{!!food.amount && <span className="dim">{food.unitId}</span>}
									</td>
								</tr>
							</React.Fragment>)}

					</tbody>
				</table>


			</Page.Body>
		</Page>
	);
}