import React, { useEffect } from "react";
import { useState } from "react";
import {  useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FoodName, MealName } from "../common/FoodName";
import Header from "../Header";
import { dispNum } from "../helpers";
import Page from "../Page";
import { doMealItemAdd, doMealSave } from "../store/meals";

export default function Meals() {

	const navigate = useNavigate();

	const [q, setQ] = useState("");
	//const [editedFood, setEditedFood] = useState(null);
	const meals = useSelector(state => state.db.meals)
	const foods = useSelector(state => state.db.foods)
	const { addFoodKey } = useParams();
	const [addFood, setAddFood] = useState(null);


	function search(items) {
		return items
			.allIds
			.map(id => items.byId[id])
			.filter(meal => !meal.isRemoved)
			.filter(meal => {
				return meal.name.toLocaleUpperCase().indexOf(q.toLocaleUpperCase()) >= 0;
			})
			.sort((a, b) => a.name.localeCompare(b.name))
			;
	}

	useEffect(() => {
		setAddFood(foods.byId[addFoodKey]);
	}, [addFoodKey]);

	const addFoodIt = (meal) => {
		doMealItemAdd(meal.id, addFood);
		navigate("/meal/"+meal.id, {replace:true});
	}

	return (<Page>
		<Page.Header>
			<Header
				right={<button className="btn title-tool-text" onClick={() => doMealSave({ name: "Új étkezés", targetCHg: 40 })} >+ Új</button>}
				title="Válassz étkezést"
				showNav={true}
			/>
			{!!addFood && 
				<div className="alert alert-primary" >
					<Link to="/meals" replace={true} className="table-tool float-end" ><i className="bi bi-x-lg"></i></Link>
					Hova rakjam ezt&nbsp;<FoodName food={addFood} />&nbsp;?
				</div>}
			<div className="search-container btn-group">
				<input type="text" autoFocus className="form-control" value={q} onChange={e => setQ(e.target.value)} />
				<button className="btn btn-outline-primary"><i className="bi bi-search" tabIndex="-1"></i></button>
			</div>
		</Page.Header>
		<Page.Body>
			<table className="table">
				<tbody>
					{search(meals)
						//.map(mealId => meals.byId[mealId])
						.map((meal, ix) => <tr key={meal.id} className={ix%2?'odd':'even'} >
							<td>
								{addFoodKey
									? <a  onClick={() => addFoodIt(meal)}><MealName meal={meal} /></a>
									: <Link to={"/meal/" + meal.id}><MealName meal={meal} /></Link>
								}</td>
							<td><span className="CH inline-CH">{dispNum(meal.targetCHg)}</span></td>
						</tr>)}
				</tbody>
			</table>
		</Page.Body>


	</Page>);
}