import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';
import Recipe from './views/Recipe';
import Foods from './views/Foods';
import Meal from './views/Meal';
import Meals from './views/Meals';
import Boxed from './views/Boxed';
import { useSelector } from 'react-redux';
import Popups from './popups/Popups';
import { login } from './store/firebase';

function App() {
	const loggedIn = useSelector(state => state.db.loggedIn);
	const connected = useSelector(state => state.db.connected);

	return (
		<div className="app">
			{typeof loggedIn != 'object'
				&& <div className="container-md login-page">
					<h1>Pityu!</h1>
					<br />
					<br />
					<br />
					{loggedIn === false
						? <div>
							<button className='btn btn-primary' onClick={() => login()}><i className='bi bi-google'></i>&nbsp;Bejelentkezés</button>
						</div>
						: JSON.stringify(loggedIn)
					}
				</div>}
			<div className="page-container container-md">
				{typeof loggedIn === 'object' && !connected && <div className="connection-status-container">
					<div className='connection-status'>
						Nincs kapcsolat!
					</div>
				</div>
				}
				{typeof loggedIn === 'object' &&
					<Router  >
						<Routes>
							<Route path="/" element={<Navigate to="/meals" replace />} />
							<Route path="/basics" element={<Foods mode='basic' />} />

							<Route path="/meals" element={<Meals />} />
							<Route path="/meals/addfood/:addFoodKey" element={<Meals />} />
							<Route path="/meal" element={<Meal />} />
							<Route path="/meal/:mealId" element={<Meal />} />
							<Route path="/meal/:mealId/foods" element={<Foods />} />
							<Route path="/meal/:mealId/change-meal" element={<Meals />} />

							<Route path="/recipe" element={<Foods mode='recipe' />} />
							<Route path="/recipe/:recipeId" element={<Recipe />} />
							<Route path="/recipe/:recipeId/foods" element={<Foods backPath="/foods" />} />

							<Route path="/boxed" element={<Foods mode='boxed' />} />
							<Route path="/boxed/:boxedId" element={<Boxed />} />

							<Route path="/all" element={<Foods mode='all' />} />
						</Routes>
						<Popups />
					</Router>}

			</div>
		</div>
	);
}

export default App;
