import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FoodName, MealName } from "../common/FoodName";
import { tableEach } from "../store/dbSlice";
import Settings from "../views/Settings";
import FoodEdit from "./FoodEdit";
import Home from "./Home";

export default function Popups() {
    const navigate = useNavigate();
    const location = useLocation();


    return (
        <React.Fragment>
            
			{/* ---------------------- Home -------------------------- */}
            <Modal show={location.hash === '#home'} centered={true}
                onHide={() => navigate(-1)}
            >
                <Home />
            </Modal>

			{/* ---------------------- FoodEdit -------------------------- */}
            <Modal show={location.hash?.startsWith('#foodedit/')} centered={true}
                onHide={() => navigate(-1)}
            >
                <FoodEdit urlId={location.hash.replace('#foodedit/','')} />
            </Modal>

			{/* ---------------------- Setting -------------------------- */}
            <Modal show={location.hash === '#settings'} centered={true}
                onHide={() => navigate(-1)}
            >
                <Settings  />
            </Modal>
        </React.Fragment>
    );
}