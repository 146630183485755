import React, { useEffect, useRef } from "react";

export function dispNum(num, d) {
	let params = {
		minimumFractionDigits: (typeof d == 'number' ? d : 0),
		maximumFractionDigits: (typeof d == 'number' ? d : 1)
	};
	return parseFloat(num)?.toLocaleString(undefined, params);
}

export function dispUnit(food) {
	return (food.unitMultip == 1 ? '' : food.unitMultip) + food.unitId;
}


export const useHash = () => {
	const [hash, setHash] = React.useState(() => window.location.hash);

	const hashChangeHandler = React.useCallback(() => {
		console.log('hash', window.location.hash)
		setHash(window.location.hash);
	}, []);

	React.useEffect(() => {
		window.addEventListener('hashchange', hashChangeHandler);
		return () => {
			window.removeEventListener('hashchange', hashChangeHandler);
		};
	}, []);

	const updateHash = React.useCallback(
		newHash => {
			if (newHash !== hash) window.location.hash = newHash;
		},
		[hash]
	);

	return [hash, updateHash];
};


export function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}