import { useEffect, useRef, useState } from "react";

export default function PInput() {
	return <span>PINP?</span>
}

const PinpNum = ({ disabled, className, value, onChange }) => {
	const inpRef = useRef();
	const [xValue, setXValue] = useState(value);

	const onXChange = (e) => {
		setXValue(e.target.value);
		if (e.target.value === 0 || e.target.value)
			onChange(e);
	}

	const handleKeyUp = (e) => {
		//key code for enter
		if (e.keyCode === 13) {
			e.preventDefault();
			e.target.blur();
		}
	}

	const handleFocus = (event) => event.target.select();

	useEffect(() => {
		setXValue(value);
	}, [value]);


	return <input ref={inpRef} type="number" className={className} disabled={disabled}
			value={xValue} onChange={onXChange} onKeyUp={handleKeyUp}
			onFocus={(e) => handleFocus(e)}
		/>
	
};





PInput.Num = PinpNum
