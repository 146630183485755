import { useNavigate } from "react-router-dom";

export const foodIconClass = (food) => {
	switch (food.kind+(food.tier||'')) {
		case 'boxed': return 'bi-archive-fill';
		case 'recipe': return 'bi-receipt';
		case 'recipechild': return 'bi-receipt';
		case 'recipeparent': return 'bi-receipt';
		case 'cook': return 'bi-hurricane';
		case 'basic': return 'bi-apple';
	}
}

export const foodClass = (food) => {
	switch (food.kind+(food.tier||'')) {
		case 'boxed': return 'food-boxed';
		case 'recipe': return 'food-recipe';
		case 'recipechild': return 'food-recipe';
		case 'recipeparent': return 'food-boxed';
		case 'basic': return 'food-basic';
	}
}



export function FoodName({ food, onClick, badge }) {

	const navigate = useNavigate();
	const textClick = () => {
		if (onClick) {
			onClick();
			return;
		}
		switch (food.kind) {
			case 'boxed': navigate(`/boxed/${food.id}`); return;
			case 'recipe': navigate(`/recipe/${food.id}`); return;
			case 'basic': navigate(`#foodedit/${food.id}`); return;
		}
	}

	return (
		<span className={`name food-${food.kind} tier-${food.tier} ${food.isRemoved ? 'removed' : ''}`} onClick={() => textClick()}>
			<i className={`bi ${foodIconClass(food)}`}></i>&nbsp;<span className="text text-food">
				{food.name}
			</span>

			{food.tier === 'child' && <span className="mybadge">
				{food.parents?.allIds?.length || '?'}
			</span>}
			
			{badge && <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
				{badge}
				<span className="visually-hidden">unread messages</span>
			</span>}

		</span>
	)
}

export function MealName({ meal, half }) {
	if (half)
		return (
			<span className={`name food-meal half`}>
				&nbsp;<i className={`bi bi-egg-fried`}></i>&nbsp;
				<span className="text text-meal-half">{meal.name}</span>
			</span>
		)
	else
		return (
			<span className={`name food-meal`}>
				&nbsp;<i className={`bi bi-egg-fried`}></i>&nbsp;
				<span className="text text-meal">{meal.name}</span>&nbsp;<span className="text-muted">({meal.targetCHg}g)</span>
			</span>
		)

}