import { initializeApp } from "firebase/app";
import { connectDatabaseEmulator, getDatabase, onValue, ref } from "firebase/database";
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithRedirect, signOut } from "firebase/auth";
import store from "./store";
import { connectedSet, loggedInSet } from "./dbSlice";


// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyB-6AfutP5YjpJ-ZYreWhAvqD2aiVYyLD0",
	authDomain: "pityu.tkrizsa.hu",
	databaseURL: "https://pityu-c727a-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "pityu-c727a",
	storageBucket: "pityu-c727a.appspot.com",
	messagingSenderId: "99870333672",
	appId: "1:99870333672:web:01e826253969b525ae475d"
};

// Initialize Firebase
const fireApp = initializeApp(firebaseConfig);


// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(fireApp);
if (window.location.hostname === "localhost") {
	// Point to the RTDB emulator running on localhost.
	//connectDatabaseEmulator(database, "localhost", 9000);
}

const connectedRef = ref(database, ".info/connected");
onValue(connectedRef, (snap) => {
	store.dispatch(connectedSet(snap.val()));
	if (snap.val() === true) {
		console.log("connected");
	} else {
		console.log("not connected");
	}
});



// -------------------- auth --------------------------------
const provider = new GoogleAuthProvider();
const auth = getAuth();


function loginError(loc, ex) {
	store.dispatch(loggedInSet(loc + ": " + (ex.message || JSON.stringify(ex))));
	console.error(loc, ex);
}


export function login() {
	//alert('login');
	try {
		signInWithRedirect(auth, provider);
	} catch (ex) {
		loginError('login', ex);
	}
}

export function logout() {
	const auth = getAuth();
	signOut(auth).then(() => {
		store.dispatch(loggedInSet(false));
	}).catch((error) => {
		console.error(error);
		alert(error);
	});
}


onAuthStateChanged(auth, (user) => {
	if (user) {
		// User is signed in, see docs for a list of available properties
		// https://firebase.google.com/docs/reference/js/firebase.User
		//const uid = user.uid;
		console.log('signedIn', user);
		store.dispatch(loggedInSet({
			uid: user.uid,
			accessToken: user.accessToken,
			displayName: user.displayName,
			photoURL: user.photoURL
		}))
		// ...
	} else {
		console.log('signedOut');
		store.dispatch(loggedInSet(false));
	}
});