import { configureStore } from '@reduxjs/toolkit';
import db, { loggedInSet } from './dbSlice';
import { getDatabase, onValue, ref } from "firebase/database";
import { initializeApp } from "firebase/app";
import { debounce } from 'debounce';

export const store = configureStore({
	reducer: { db }//, preloadedState: loadState()
});

store.subscribe(
	debounce(() => {
		//saveState(store.getState().db);
	}, 800)
);
export default store;

