import { ensureFloat, foodDelete, foodSave, roundFloat, savedFoodIdSet, tableEach } from './dbSlice';
import { onChildAdded, onChildChanged, onChildRemoved, ref, push, set, remove, update, off } from "firebase/database";
import { store } from './store';
import { database } from './firebase';
import { signalMealCalculate } from './meals';


const foodsRef = ref(database, 'foods');


export function subscribeFoods() {

    // remove old listeners (needed because react dev partially reloads this file and old listeners keep listening)
    off(foodsRef);

    onChildAdded(foodsRef, (data) => {
        //console.log('food.added', data.key, data.val());
        foodLoaded(data.key, data.val());
    });

    onChildChanged(foodsRef, (data) => {
        console.log('food.changed++', data.key, data.val());
        foodLoaded(data.key, data.val());
    });

    onChildRemoved(foodsRef, (data) => {
        console.log('food.removed', data.key);
        store.dispatch(foodDelete(data.key));
    });
}


function foodLoaded(key, food) {
    food.id = key;
    const dbItems = food.items || {};
    food.items = { allIds: [], byId: {} };
    for (let [itemKey, dbItem] of Object.entries(dbItems)) {
        food.items.allIds.push(itemKey);
        food.items.byId[itemKey] = { ...dbItem, id: itemKey };
    }
    const dbParents = food.parents || {};
    food.parents = { allIds: [] };
    for (let [parentKey, dbParent] of Object.entries(dbParents)) {
        food.parents.allIds.push(parentKey);
    }
    store.dispatch(foodSave(food));
}


// call it from React componenet useEffect()
export function doFoodCalculate(key) {
    let updates = checkFoodCalculate(key);
    if (updates && Object.entries(updates).length > 0) {
        const itemRef = ref(database, 'foods/' + key);
        update(itemRef, updates);
    }
}

// call it from action
function signalFoodCalculate(key) {
    console.log('-> signalFoodCalculate', key);
    setTimeout(() => {
        doFoodCalculate(key);
    }, 100);
}

function checkFoodCalculate(key) {
    // --------------- calculate anyway ... not sure it will be saved -----------------
    const state = store.getState().db;
    const food = state.foods.byId[key];

    if (!food) {
        //console.warn('-> checkFoodCalculate no food', key);
        return;
    }
    if (food.kind !== 'recipe')
        return;


    let updates = {};

    let autoName = '';
    let sumCHg = 0;
    let tier = (food.parents?.allIds || []).length > 0 ? 'child' : '';

    let unitId = food.unitId || 'g';
    if (unitId !== food.unitId)
        updates.unitId = unitId;

    let unitMultip = food.unitMultip || 100;
    if (unitMultip !== food.unitMultip)
        updates.unitMultip = unitMultip;


    for (let itemId of food.items?.allIds || []) {
        let item = food.items.byId[itemId];
        let itemFood = state.foods.byId[item.foodKey];
        let CHg = roundFloat((ensureFloat(item.amount) * ensureFloat(itemFood.chPerUnit)) / ensureFloat(itemFood.unitMultip));
        if (item.CHg !== CHg)
            updates['items/' + itemId + '/CHg'] = CHg;
        sumCHg += CHg;
        autoName += (autoName ? ' + ' : '') + itemFood.name;
        if (itemFood.kind === 'recipe') {
            if (!tier)
                tier = 'parent'
            else if (tier != 'parent')
                tier = 'error';
        }
    }

    if (ensureFloat(food.netWeightG) > 0) {
        let chPerUnit = roundFloat((sumCHg / ensureFloat(food.netWeightG)) * ensureFloat(unitMultip));
        if (food.chPerUnit !== chPerUnit) {
            updates.chPerUnit = chPerUnit;
        }
    } else {
        if (food.chPerUnit !== 0)
            updates.chPerUnit = 0;
    }

    if (!food.isRenamed) {
        autoName = autoName || (food.kind === 'recipe' ? 'Új recept' : 'Új étel');
        if (food.name !== autoName)
            updates.name = autoName;
    }

    if (sumCHg !== food.sumCHg) {
        updates.sumCHg = sumCHg;
    }

    if (food.tier !== tier) {
        updates.tier = tier;
    }
    console.log('-> checkFoodCalculate: ' + (Object.entries(updates).length > 0 ? 'UPD' : 'NO'), key, updates);

    // ---------- recalculate all parents as well ---------------
    if (food.parents) {
        for(let parentKey of food.parents.allIds) {
            doFoodCalculate(parentKey);
        }
    }

    return updates;
}



// ==================================================== ACTIONS ====================================================
// ----------------------------------- FOOD SAVE -----------------------------------
export const doFoodSave = food => {
    const state = store.getState().db;
    food.kind ||= 'basic';
    let foodKey = food.id;

    if (food.id) {
        signalFoodCalculate(food.id);
        const itemRef = ref(database, 'foods/' + food.id);
        delete food.id;
        set(itemRef, food).catch(ex => console.error('exx', ex));
    } else {
        delete food.id;
        const foodListRef = ref(database, 'foods');
        const newFoodRef = push(foodListRef, food);
        foodKey = newFoodRef.key;
        signalFoodCalculate(foodKey);
        food.id = foodKey; // a meghívó használhatja!
        if (food.kind == 'basic')
            store.dispatch(savedFoodIdSet(foodKey)); // a Foods.jsx használja
    }

    if (food.kind == 'basic') {
        for (let parent of tableEach(state.foods)) {
            if (!parent.isRemoved && parent.kind === 'recipe') {
                for (let item of tableEach(parent.items)) {
                    if (item.foodKey == foodKey) {
                        signalFoodCalculate(parent.id);
                        break;
                    }
                }
            }
        }
        for (let meal of tableEach(state.meals)) {
            if (!meal.isRemoved) {
                for (let item of tableEach(meal.items)) {
                    if (item.foodKey == foodKey) {
                        signalMealCalculate(meal.id);
                        break;
                    }
                }
            }
        }
    }
};


// ----------------------------------- FOOD ITEM ADD -----------------------------------
export function doFoodItemAdd(parentFoodKey, childFood) {
    signalFoodCalculate(parentFoodKey);
    if (childFood.kind === 'recipe')
        signalFoodCalculate(childFood.id);

    const state = store.getState().db;
    const parentFood = state.foods.byId[parentFoodKey];

    const childItem = {
        foodKey: childFood.id,
        amount: childFood.amount || 0
    };
    const itemListRef = ref(database, 'foods/' + parentFoodKey + '/items');
    push(itemListRef, childItem);
    if (childFood.kind === 'recipe') {
        const childsParentsRef = ref(database, 'foods/' + childFood.id + '/parents/' + parentFoodKey);
        set(childsParentsRef, true);
    }
}

// ----------------------------------- FOOD ITEM DELETE -----------------------------------
export function doFoodItemDelete(parentFoodKey, itemKey, childFoodKey) {
    signalFoodCalculate(parentFoodKey);
    const state = store.getState().db;
    const parentFood = state.foods.byId[parentFoodKey];

    if (itemKey) {
        let childFoodKey = parentFood.items.byId[itemKey].foodKey;
        let childFood = state.foods.byId[childFoodKey];
        if (childFood.kind === 'recipe')
            signalFoodCalculate(childFoodKey);

        const itemRef = ref(database, 'foods/' + parentFoodKey + '/items/' + itemKey);
        remove(itemRef);

        if (childFood.kind === 'recipe') {
            const childsParentsRef = ref(database, 'foods/' + childFoodKey + '/parents/' + parentFoodKey);
            remove(childsParentsRef);
        }

    }
    if (childFoodKey) {
        let childFood = state.foods.byId[childFoodKey];
        if (childFood.kind === 'recipe')
            signalFoodCalculate(childFoodKey);

        for (let itemKey of parentFood.items.allIds) {
            let item = parentFood.items.byId[itemKey];
            if (item.foodKey === childFoodKey) {
                const itemRef = ref(database, 'foods/' + parentFoodKey + '/items/' + itemKey);
                remove(itemRef);

                if (childFood.kind === 'recipe') {
                    const childsParentsRef = ref(database, 'foods/' + childFoodKey + '/parents/' + parentFoodKey);
                    remove(childsParentsRef);
                }
            }
        }
    }
}

// ----------------------------------- FOOD ITEM AMOUNT SET -----------------------------------
export function doFoodItemAmountSet(foodKey, itemKey, amount) {
    console.log('itemamountset', amount);
    signalFoodCalculate(foodKey);
    const itemRef = ref(database, 'foods/' + foodKey + '/items/' + itemKey);
    update(itemRef, { amount });
}

// ----------------------------------- FOOD NET WEIGHT SET -----------------------------------
export function doFoodNetWeightSet(foodKey, weight) {
    signalFoodCalculate(foodKey);
    const foodRef = ref(database, 'foods/' + foodKey);
    let netWeightG = parseFloat(weight);
    update(foodRef, { netWeightG });
}
// ----------------------------------- FOOD RENAME -----------------------------------
export function doFoodRename(foodKey, name) {
    const foodRef = ref(database, 'foods/' + foodKey);
    update(foodRef, { name, isRenamed: true });
}


// ----------------------------------- FOOD DELETE -----------------------------------
export function doFoodDelete(foodKey) {
    const state = store.getState().db;
    const food = state.foods.byId[foodKey];
    const foodRef = ref(database, 'foods/' + foodKey);
    update(foodRef, { isRemoved: true });
}

// ----------------------------------- FOOD UNDO DELETE -----------------------------------
export function doFoodUndoDelete(foodKey) {
    const state = store.getState().db;
    const food = state.foods.byId[foodKey];
    const foodRef = ref(database, 'foods/' + foodKey);
    update(foodRef, { isRemoved: false });
}

// ----------------------------------- FOOD FINAL DELETE -----------------------------------
export function doFoodFinalDelete(foodKey) {
    const state = store.getState().db;
    const food = state.foods.byId[foodKey];
    const foodRef = ref(database, 'foods/' + foodKey);
    remove(foodRef);
}

// ----------------------------------- FOOD FINISHED -----------------------------------
function copyFoodforDb(oldFood, removes) {
    let food = JSON.parse(JSON.stringify(oldFood));
    let dbItems = food.items.byId;
    for (let ix in dbItems) {
        let dbItem = dbItems[ix];
        delete dbItem.id;
    }
    food.items = dbItems;
    for (let rix of removes || []) {
        delete food[rix];
    }
    delete food.id;
    return food;
}

export function doRecipeIntoBox(recipeKey) {
    const foodListRef = ref(database, 'foods');
    const state = store.getState().db;
    const recipe = state.foods.byId[recipeKey];
    if (!recipe || recipe.kind !== 'recipe')
        throw Error("doRecipeIntoBox called on not recipe");

    const newBoxed = copyFoodforDb(recipe, ['boxeds', 'items', 'netWeightG', 'sumCHg', 'parents', 'tier']);
    newBoxed.kind = 'boxed';
    newBoxed.amount = recipe.netWeightG;
    newBoxed.recipeKey = recipeKey;
    const newBoxedRef = push(foodListRef, newBoxed);

}


export function doFoodUndelete(foodKey) {
    update(ref(database, '/foods/' + foodKey), { isRemoved: false });
}

export function doFoodUnitSet(foodKey, unitId) {
    const state = store.getState().db;
    const food = state.foods.byId[foodKey];
    if (food.kind != 'basic')
        signalFoodCalculate(foodKey);
    const unitMultip = state.units.byId[unitId].defaultMultip;
    update(ref(database, '/foods/' + foodKey), { unitId, unitMultip });
}

export function doBoxedSplit(boxedKey, amount) {
    const state = store.getState().db;
    const boxed = state.foods.byId[boxedKey];
    if (!boxed || boxed.kind !== 'boxed' || boxed.amount < amount)
        throw Error(`Boxing failed`);

    // --- add new boxed ---
    const newBoxed = copyFoodforDb(boxed);
    newBoxed.amount = amount;

    const foodListRef = ref(database, 'foods');
    const newBoxedRef = push(foodListRef, newBoxed);

    // --- decrease amount of original ---
    const boxedRef = ref(database, 'foods/' + boxedKey);
    update(boxedRef, { amount: boxed.amount - amount });

}

export function doBoxedAmountSet(boxedKey, amount) {
    const state = store.getState().db;
    const boxed = state.foods.byId[boxedKey];
    if (!boxed || boxed.kind !== 'boxed' || boxed.amount <= 0)
        throw Error(`Boxed amount set failed`);
    update(ref(database, 'foods/' + boxedKey), { amount });
}


export function doBoxedFIX(boxedKey) {
    const state = store.getState().db;
    const boxed = state.foods.byId[boxedKey];
    let cook;
    if (boxed.cookKey) 
        cook = state.foods.byId[boxed.cookKey]
    if (cook && cook.recipeKey) {
        update(ref(database, 'foods/' + boxedKey), { recipeKey : cook.recipeKey, cookKey : null });
    } else {
        console.warn('NO recipeKey', boxed, cook);
    }
}