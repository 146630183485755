import { createSlice } from '@reduxjs/toolkit'




export const UNITS = {
	allIds: ['g', 'db', 'ml'],
	byId: {
		'g': { id: 'g', defaultMultip: 100 }
		, 'ml': { id: 'ml', defaultMultip: 100 }
		, 'db': { id: 'db', defaultMultip: 1 }
	}
}

export function deepCopy(obj) {
	return JSON.parse(JSON.stringify(obj));
}

export function ensureFloat(n) {
	if (typeof n == 'string')
		n = n.replace(',', '.');
	return parseFloat(n) || 0;
}

export function roundFloat(n) {
	return Math.round(n * 10) / 10;
}

export function tableEnsure(obj, prop) {
	obj[prop] ||= { byId: {}, allIds: [] };
}

/*function tableNextId(table) {
	return (table.allIds.reduce((prev, curr) => Math.max(prev, parseInt(curr)), 0) + 1) + '';
}*/

export function tableAdd(table, item) {
	//item.id = tableNextId(table);
	if (!item.id)
		throw new Error(`tableAdd id missing`);
	table.allIds.push(item.id);
	table.byId[item.id] = item;
	return item;
}


export function tableDelete(table, id) {
	delete table.byId[id];
	table.allIds.splice(table.allIds.indexOf(id), 1);
}

export function tableEach(table) {
	return table.allIds.map(id => table.byId[id]);
}




const INITIAL_STATE = {
	foods: { byId: {}, allIds: [] },
	meals: { byId: {}, allIds: [] },
	units: UNITS,
	savedFoodId : null,
	loggedIn : 'checking...',
	connected : false
};


export const dbSlice = createSlice({
	name: 'food',
	initialState: INITIAL_STATE,
	reducers: {
		loggedInSet : (state, action) => {
			state.loggedIn = action.payload;
		},
		connectedSet : (state, action) => {
			state.connected = action.payload;
		},
		// ===================================== MEAL =====================================
		mealSave: (state, action) => {
			let meal = action.payload;
			if (!meal.id)
				throw Error('No meal id in mealSave!');
			if (!state.meals.byId[meal.id]) {
				tableAdd(state.meals, meal);
			} else {
				state.meals.byId[meal.id] = meal;
			};
		},

		mealDelete: (state, action) => {
			const mealKey = action.payload;
			tableDelete(state.meals, mealKey);
		},
		
		// ===================================== FOOD =====================================
		foodSave: (state, action) => {
			let food = action.payload;
			if (!food.id)
				throw Error('No food id in foodSave!');
			if (!state.foods.byId[food.id]) {
				tableAdd(state.foods, food);
			} else {
				state.foods.byId[food.id] = food;
			};
		},
		foodDelete: (state, action) => {
			const foodKey = action.payload;
			tableDelete(state.foods, foodKey);
		},

		savedFoodIdSet: (state, action) => {
			state.savedFoodId = action.payload;
		}
	}
});

export const {
	mealSave, mealDelete
	, foodSave, foodDelete
	, savedFoodIdSet
	, loggedInSet, connectedSet
} = dbSlice.actions
export default dbSlice.reducer;



