import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom";
import { tableEach } from "../store/dbSlice";
import { doFoodDelete, doFoodSave, doFoodUndelete } from "../store/foods";


export default function FoodEdit({ urlId }) {

	const dispatch = useDispatch();

	const foods = useSelector(state => state.db.foods);
	const units = useSelector(state => state.db.units);

	const [foodId, setFoodId] = useState(null);
	const [food, setFood] = useState(null);
	const [name, setName] = useState("");
	const [chPerUnit, setChPerUnit] = useState(0);
	const [unitId, setUnitId] = useState('g');
	const [unitMultip, setUnitMultip] = useState(100);

	const navigate = useNavigate();

	function submit(e) {
		//e.preventDefault();
		let toSave = { id: food.id, name, kind: 'basic', unitId, unitMultip, chPerUnit };
		doFoodSave(toSave);
		navigate(-1);
	}

	useEffect(() => {
		let _food;
		if (urlId.startsWith("new/")) {
			setFoodId(null);
			_food = { name : decodeURIComponent(urlId.substr(4))};
		} else {
			_food = foods.byId[urlId];
			setFoodId(_food?.id || null);
		}
		
		setFood(_food);

		setName(_food?.name || "");
		setChPerUnit(_food?.chPerUnit || 0);
		setUnitId(_food?.unitId || 'g');
		setUnitMultip(_food?.unitMultip || 100);

	}, [urlId, foods.byId]);

	const unitChanged = (uId) => {
		let u = units.byId[uId];
		setUnitId(u.id);
		setUnitMultip(u.defaultMultip);
	}

	const validUnitMultip = (um) => {
		return Math.max(Math.abs(parseInt(um)||1), 1);
	}

	const deleteIt = () => {
		if (window.confirm("Biztosan törlöd?")) {
			doFoodDelete(foodId);
			navigate(-1);
		}
	}

	const undeleteIt = () => {
		doFoodUndelete(foodId);
		setFood(prev => ({...prev, isRemoved:false}));
	}


	return (
		<React.Fragment>
			<Modal.Header closeButton>
				{/*<div className="text-muted">{foodId}</div>*/}
			</Modal.Header>
			<Modal.Body>
				{food?.isRemoved && <div className="alert alert-danger">Törölt étel!</div>}
				<div>
					<label className="form-label">Név</label>
					<input type="text" autoFocus className="form-control" style={{fontWeight:(name?'bold':'normal')}}
						placeholder="Étel neve" 
						value={name} onChange={e => setName(e.target.value)} /><br />
				</div>
				<div>
					<label className="form-label">Egység</label>
					<div className="input-group">
						<input type="number" className="form-control AMT" style={{textAlign:'right'}} 
							value={unitMultip} onChange={e => setUnitMultip(validUnitMultip( e.target.value))} /><br />
						<select className="form-control AMT" value={unitId} onChange={(e) => unitChanged(e.target.value)}>
							{tableEach(units).map(unit => <option key={unit.id} value={unit.id}>{unit.id}</option>)}
						</select>
					</div>
				</div>
				<div>
					<label className="form-label">Szénhidrát (g / {unitMultip}{unitId})</label>
					<input type="number" className="form-control CH" value={chPerUnit} onChange={e => setChPerUnit(e.target.value)} />
				</div>
			</Modal.Body>
			<Modal.Footer>
				{foodId && !food?.isRemoved && <button className="btn btn-outline-primary" onClick={()=>deleteIt()}><i className="bi bi-trash"></i>&nbsp;Töröl</button>}
				{name && <a className="btn btn-outline-primary" href={`https://google.com/search?q=${name}+szénhidrát`} target="_blank" ><i className="bi bi-google"></i>&nbsp;Google</a>}
				{foodId && <Link className="btn btn-outline-primary" to={`/meals/addfood/${food.id}`} ><i className={`bi bi-egg-fried`}></i>&nbsp;Étkezésre</Link>}
				{foodId && food?.isRemoved && <button className="btn btn-primary" onClick={()=>undeleteIt()}><i className="bi bi-recycle"></i>&nbsp;Mégsem törölt!</button>}
				{!food?.isRemoved && <button type="submit" className="btn btn-primary" 
					disabled={!name || unitMultip<1} 
					onClick={() => submit()} ><i className="bi bi-save"></i>&nbsp;Ment</button>}
			</Modal.Footer>
		</React.Fragment>


	);


}