import { ensureFloat, mealDelete, mealSave, roundFloat } from './dbSlice';
import { onChildAdded, onChildChanged, onChildRemoved, ref, push, set, remove, update, off } from "firebase/database";
import { store } from './store';
import { database } from './firebase';


const mealsRef = ref(database, 'meals');


export function subscribeMeals() {

    // remove old listeners (needed because react dev partially reloads this file and old listeners keep listening)
    off(mealsRef);

    onChildAdded(mealsRef, (data) => {
        //console.log('meal.added', data.key, data.val());
        mealLoaded(data.key, data.val());
    });

    onChildChanged(mealsRef, (data) => {
        console.log('meal.changed', data.key, data.val());
        mealLoaded(data.key, data.val());
    });

    onChildRemoved(mealsRef, (data) => {
        console.log('meal.removed', data.key);
        store.dispatch(mealDelete(data.key));
    });
}


function mealLoaded(key, meal) {
    meal.id = key;
    const dbItems = meal.items || {};
    meal.items = { allIds: [], byId: {} };
    for (let [itemKey, dbItem] of Object.entries(dbItems)) {
        meal.items.allIds.push(itemKey);
        meal.items.byId[itemKey] = { ...dbItem, id: itemKey };
    }

    store.dispatch(mealSave(meal));
}


// call it from React componenet useEffect()
export function doMealCalculate(key) {
    let updates = checkMealCalculate(key);
    if (updates && Object.entries(updates).length > 0) {
        const itemRef = ref(database, 'meals/' + key);
        update(itemRef, updates);
    }
}

// call it from action
export function signalMealCalculate(key) {
    console.log('-> signalMealCalculate', key);
    setTimeout(() => {
        doMealCalculate(key);
    }, 100);
}


function checkMealCalculate(key) {
    // --------------- calculate anyway ... not sure it will be saved -----------------
    const state = store.getState().db;
    const meal = state.meals.byId[key];

    if (!meal) {
        console.warn('-> checkMealCalculate no meal', key);
        return;
    }


    let updates = {};

	let sumCHg = 0;
	for (let itemId of meal.items?.allIds || []) {
		let item = meal.items.byId[itemId];
		let food = state.foods.byId[item.foodKey];
		if (item.isCHSet) {
			let amount = roundFloat((ensureFloat(item.CHg) * ensureFloat(food.unitMultip)) / ensureFloat(food.chPerUnit));
            if (item.amount !== amount)
                updates['items/' + itemId + '/amount'] = amount;
            sumCHg += ensureFloat(item.CHg);
		} else {
			let CHg = roundFloat((ensureFloat(item.amount) * ensureFloat(food.chPerUnit)) / ensureFloat(food.unitMultip));
            if (item.CHg !== CHg)
                updates['items/' + itemId + '/CHg'] = CHg;

            sumCHg += CHg;
		}
		
	}

    if (sumCHg !== meal.sumCHg) {
        updates.sumCHg = sumCHg;
    }

    console.log('-> checkMealCalculate: ' + (Object.entries(updates).length > 0 ? 'UPD' : 'NO'), key, updates);
    return updates;
}


// ==================================================== ACTIONS ====================================================
// ----------------------------------- MEAL SAVE -----------------------------------
export function doMealSave(meal)  {

    meal.name ||= "Új étkezés";
    meal.targetCHg ||= 40;

    if (meal.id) {
        signalMealCalculate(meal.id);
        const itemRef = ref(database, 'meals/' + meal.id);
        delete meal.id;
        set(itemRef, meal);
    } else {
        delete meal.id;
        const mealListRef = ref(database, 'meals');
        const newMealRef = push(mealListRef, meal);
        signalMealCalculate(newMealRef.key);
        meal.id = newMealRef.key; // a meghívó használja!
    }
};

// ----------------------------------- MEAL DELETE -----------------------------------
export function doMealDelete(mealKey) {
    update(ref(database, 'meals/'+mealKey), {isRemoved:true});
}

// ----------------------------------- MEAL RENAME -----------------------------------
export function doMealRename(mealKey, name) {
    update(ref(database, 'meals/'+mealKey), {name});
}

// ----------------------------------- MEAL TARGET CHg SET -----------------------------------
export function doMealTargetCHgSet(mealKey, targetCHg) {
    update(ref(database, 'meals/'+mealKey), {targetCHg});
}

// ----------------------------------- MEAL ITEM ADD -----------------------------------
export function doMealItemAdd(mealKey, food) {
    signalMealCalculate(mealKey);
    const state = store.getState().db;
    const meal = state.meals.byId[mealKey];

    const childItem = {
        foodKey: food.id,
        amount:  0
    };
    const itemListRef = ref(database, 'meals/' + mealKey + '/items');
    push(itemListRef, childItem);    
}

// ----------------------------------- MEAL ITEM AMOUNT SET -----------------------------------
export function doMealItemAmountSet(mealKey, itemKey, amount) {
    signalMealCalculate(mealKey);
    update(ref(database, 'meals/'+mealKey+'/items/'+itemKey), {amount, isCHSet:false});
}

// ----------------------------------- MEAL ITEM CH SET -----------------------------------
export function doMealItemCHSet(mealKey, itemKey, CHg) {
    signalMealCalculate(mealKey);
    update(ref(database, 'meals/'+mealKey+'/items/'+itemKey), {CHg, isCHSet:true});
}

// ----------------------------------- MEAL ITEM DELETE -----------------------------------
export function doMealItemDelete(mealKey, itemKey, foodKey) {
    signalMealCalculate(mealKey);
    const state = store.getState().db;
    const meal = state.meals.byId[mealKey];

    if (itemKey) {
        const itemRef = ref(database, 'meals/' + mealKey + '/items/' + itemKey);
        remove(itemRef);
    }
    if (foodKey) {
        for (let itemKey of meal.items.allIds) {
            let item = meal.items.byId[itemKey];
            if (item.foodKey === foodKey) {
                const itemRef = ref(database, 'meals/' + mealKey + '/items/' + itemKey);
                remove(itemRef);
            }
        }
    }
}
