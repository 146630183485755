import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FoodName, MealName } from "../common/FoodName";
import { tableEach } from "../store/dbSlice";
import { logout } from "../store/firebase";
import styles from "./home.scss";

export default function Home() {
    const foods = useSelector(state => state.db.foods)
    const meals = useSelector(state => state.db.meals)
    const loggedIn = useSelector(state => state.db.loggedIn)

    const [topMeals, setTopMeals] = useState([]);

    useEffect(() => {
        const _topMeals = [];
        let mealCount = 6;
        for (let m of tableEach(meals)) {
            if (mealCount && !m.isRemoved) {
                _topMeals.push(m);
                mealCount--;
            }
        }
        setTopMeals(_topMeals);
    }, [foods, meals]);


    const navigate = useNavigate();
    const logoutIt = () => {
        navigate('/');
        logout();
    }
    /*


    */

return (
    <React.Fragment>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='home'>
            <NavLink to="/meals" className='food-meal level1'><i className="bi bi-egg-fried"></i>&nbsp;Étkezés</NavLink>
            <div className="row">
                {topMeals.map(meal => <div  key={meal.id}  className="col-6">
                    <NavLink to={`/meal/${meal.id}`} >
                        <MealName meal={meal} half={true} />
                    </NavLink>
                </div>
                )}

            </div>
            <NavLink to="/recipe" className='food-recipe level1'><i className="bi bi-receipt"></i>&nbsp;Receptek</NavLink>
            <NavLink to="/basics" className='food-basic level1'><i className="bi bi-apple"></i>&nbsp;Alapanyagok</NavLink>
            <NavLink to="/boxed" className='food-boxed level1' ><i className="bi bi-archive-fill"></i>&nbsp;Dobozolva</NavLink>
        </Modal.Body>
        <Modal.Footer>
            <Link to="/all" className='btn btn-outline-primary'>A</Link>
            <Link to="#settings" className='btn btn-outline-primary'><i className="bi bi-gear"></i>&nbsp;Settings</Link>
            <button className="btn btn-outline-primary" onClick={logoutIt}  ><img src={loggedIn.photoURL} referrerPolicy="no-referrer" className="user-photo" />&nbsp;Logout</button>
        </Modal.Footer>
    </React.Fragment>
);
}